window.Client = {
	onRender: function(screenId){
	    // cometly custom event - this will only trigger on quiz start
	    if(screenId == "start-c8d649e6"){
	        comet('custom_event_1');
	        console.log("hey start");
	    }
	    
		if(screenId == "screen-fa9b60e0"){
		    var myInterval = setInterval(checkvar, 100);
		    function checkvar() {
	            var regex = /^.{4,}$/;
                
                var spanValue = document.querySelector("#text-block-rich-text-366121fc .formula-replace").textContent;
                
	            if(regex.test(spanValue)){
            		console.log(spanValue);
            	}else{
            	    clearInterval(myInterval);
		            console.log(spanValue);
		            
		            //opt1 start
                	var ddate = Number(spanValue);
                
                	// Get the current date
                	var currentDate = new Date();
                
                	// Add the number of months to the current date
                	currentDate.setMonth(currentDate.getMonth() + ddate);
                
                	// Options to format the date
                	var options = { year: 'numeric', month: 'short', day: 'numeric' };
                
                	// Format the date using toLocaleDateString
                	var formattedDate = currentDate.toLocaleDateString('en-US', options);
                
                	// Output the formatted date
                	document.querySelector("#text-block-rich-text-d3c2ec6b p").textContent = formattedDate;
                
                	var curweight = document.querySelector("#text-block-rich-text-01507adf .variable-replace").textContent;
                
                	var goalweight = document.querySelector("#text-block-rich-text-fe2ed539 .variable-replace").textContent;
                
                	var twl = curweight - goalweight;
                	var initialNum1 = curweight - (twl * 0.333333333);
                	var fnumber = Math.round(curweight - (twl * 0.333333333));
                	var fnumber2 = Math.round(initialNum1 - (twl * 0.333333333));
                
                
                	var spanCont = document.createElement("div");
                	spanCont.id = "spanCont";
                	document.querySelector("#image-block-img-2d70a335 div").appendChild(spanCont);
                
                	var spancurweight = document.createElement("span");
                	spancurweight.id = "spancurweight";
                	spancurweight.textContent = curweight + "lbs";
                
                	var fnum1 = document.createElement("span");
                	fnum1.id = "fnum1";
                	fnum1.textContent = fnumber + "lbs";
                
                	var fnum2 = document.createElement("span");
                	fnum2.id = "fnum2";
                	fnum2.textContent = fnumber2 + "lbs";
                
                	var trgWeight = document.createElement("span");
                	trgWeight.id = "trgWeight";
                	trgWeight.innerHTML = "<span class='trgtSpan'>" + goalweight + "lbs</span><br>" + formattedDate;
                
                
                	document.querySelector("#spanCont").appendChild(spancurweight);
                	document.querySelector("#spanCont").appendChild(fnum1);
                	document.querySelector("#spanCont").appendChild(fnum2);
                	document.querySelector("#spanCont").appendChild(trgWeight);
            	}
	
		    }
		}
        if(screenId == "id-8bee09aa"){
        	var myInterval_v2 = setInterval(checkvar_v2, 100);
            	function checkvar_v2() {
            		var regex_v2 = /^.{4,}$/;
            		
            		var spanValue_v2 = document.querySelector("#text-block-id-fdeb6948 .formula-replace").textContent;
            		
            		if(regex_v2.test(spanValue_v2)){
            			console.log(spanValue_v2);
            		}else{
            			clearInterval(myInterval_v2);
            			console.log(spanValue_v2);
            			
            			//opt1 start
            			var ddate_v2 = Number(spanValue_v2);
            		
            			// Get the current date
            			var currentDate_v2 = new Date();
            		
            			// Add the number of months to the current date
            			currentDate_v2.setMonth(currentDate_v2.getMonth() + ddate_v2);
            		
            			// Options to format the date
            			var options_v2 = { year: 'numeric', month: 'short', day: 'numeric' };
            		
            			// Format the date using toLocaleDateString
            			var formattedDate_v2 = currentDate_v2.toLocaleDateString('en-US', options_v2);
            		
            			// Output the formatted date
            			document.querySelector("#text-block-id-f62b6095 p").textContent = formattedDate_v2;
            		
            			var curweight_v2 = document.querySelector("#text-block-id-64c82f1b .variable-replace").textContent;
            		
            			var goalweight_v2 = document.querySelector("#text-block-id-6b6ca493 .variable-replace").textContent;
            		
            			var twl_v2 = curweight_v2 - goalweight_v2;
            			var initialNum1_v2 = curweight_v2 - (twl_v2 * 0.333333333);
            			var fnumber_v2 = Math.round(curweight_v2 - (twl_v2 * 0.333333333));
            			var fnumber2_v2 = Math.round(initialNum1_v2 - (twl_v2 * 0.333333333));
            		
            		
            			var spanCont_v2 = document.createElement("div");
            			spanCont_v2.id = "spanCont_v2";
            			document.querySelector("#image-block-id-3df174d7 div").appendChild(spanCont_v2);
            		
            			var spancurweight_v2 = document.createElement("span");
            			spancurweight_v2.id = "spancurweight_v2";
            			spancurweight_v2.textContent = curweight_v2 + "lbs";
            		
            			var fnum1_v2 = document.createElement("span");
            			fnum1_v2.id = "fnum1_v2";
            			fnum1_v2.textContent = fnumber_v2 + "lbs";
            		
            			var fnum2_v2 = document.createElement("span");
            			fnum2_v2.id = "fnum2_v2";
            			fnum2_v2.textContent = fnumber2_v2 + "lbs";
            		
            			var trgWeight_v2 = document.createElement("span");
            			trgWeight_v2.id = "trgWeight_v2";
            			trgWeight_v2.innerHTML = "<span class='trgtSpan_v2'>" + goalweight_v2 + "lbs</span><br>" + formattedDate_v2;
            		
            		
            			document.querySelector("#spanCont_v2").appendChild(spancurweight_v2);
            			document.querySelector("#spanCont_v2").appendChild(fnum1_v2);
            			document.querySelector("#spanCont_v2").appendChild(fnum2_v2);
            			document.querySelector("#spanCont_v2").appendChild(trgWeight_v2);
            		}
            
            	}
            }
         if(screenId == "id-f110109e"){
        	var myInterval_v3 = setInterval(checkvar_v3, 100);
        	function checkvar_v3() {
        		var regex_v3 = /^.{4,}$/;
        		
        		var spanValue_v3 = document.querySelector("#text-block-id-aae823d9 .formula-replace").textContent;
        		
        		if(regex_v3.test(spanValue_v3)){
        			console.log(spanValue_v3);
        		}else{
        			clearInterval(myInterval_v3);
        			console.log(spanValue_v3);
        			
        			//opt1 start
        			var ddate_v3 = Number(spanValue_v3);
        		
        			// Get the current date
        			var currentDate_v3 = new Date();
        		
        			// Add the number of months to the current date
        			currentDate_v3.setMonth(currentDate_v3.getMonth() + ddate_v3);
        		
        			// Options to format the date
        			var options_v3 = { year: 'numeric', month: 'short', day: 'numeric' };
        		
        			// Format the date using toLocaleDateString
        			var formattedDate_v3 = currentDate_v3.toLocaleDateString('en-US', options_v3);
        		
        			// Output the formatted date
        			document.querySelector("#text-block-id-89cff363 p").textContent = formattedDate_v3;
        		
        			var curweight_v3 = document.querySelector("#text-block-id-18b3730a .variable-replace").textContent;
        		
        			var goalweight_v3 = document.querySelector("#text-block-id-5c3a552d .variable-replace").textContent;
        		
        			var twl_v3 = curweight_v3 - goalweight_v3;
        			var initialNum1_v3 = curweight_v3 - (twl_v3 * 0.333333333);
        			var fnumber_v3 = Math.round(curweight_v3 - (twl_v3 * 0.333333333));
        			var fnumber2_v3 = Math.round(initialNum1_v3 - (twl_v3 * 0.333333333));
        		
        		
        			var spanCont_v3 = document.createElement("div");
        			spanCont_v3.id = "spanCont_v3";
        			document.querySelector("#image-block-id-11acf03a div").appendChild(spanCont_v3);
        		
        			var spancurweight_v3 = document.createElement("span");
        			spancurweight_v3.id = "spancurweight_v3";
        			spancurweight_v3.textContent = curweight_v3 + "lbs";
        		
        			var fnum1_v3 = document.createElement("span");
        			fnum1_v3.id = "fnum1_v3";
        			fnum1_v3.textContent = fnumber_v3 + "lbs";
        		
        			var fnum2_v3 = document.createElement("span");
        			fnum2_v3.id = "fnum2_v3";
        			fnum2_v3.textContent = fnumber2_v3 + "lbs";
        		
        			var trgWeight_v3 = document.createElement("span");
        			trgWeight_v3.id = "trgWeight_v3";
        			trgWeight_v3.innerHTML = "<span class='trgtSpan_v3'>" + goalweight_v3 + "lbs</span><br>" + formattedDate_v3;
        		
        		
        			document.querySelector("#spanCont_v3").appendChild(spancurweight_v3);
        			document.querySelector("#spanCont_v3").appendChild(fnum1_v3);
        			document.querySelector("#spanCont_v3").appendChild(fnum2_v3);
        			document.querySelector("#spanCont_v3").appendChild(trgWeight_v3);
        		}
        
        	}
        }
        if(screenId == "screen-d207b01f"){
            document.getElementById("select-216fd0e1").addEventListener("change", stateChange);
            
            function stateChange() {
              var stateSelected = document.getElementById("select-216fd0e1").value;
              if(stateSelected == "Arkansas" || stateSelected == "California" || stateSelected == "Louisiana" || stateSelected == "Alabama" || stateSelected == "Mississippi"){
                  document.getElementById('mco-139dfc8d').checked = true;
                  
              }else{
                  document.getElementById('mco-386e19a9').checked = true;
              }
              
              
            }
        }
        if(screenId == "screen-a56811a6"){
            var eli = "";
            var weightLossInj = "";
            
            //get the value of the tried methods, will be used to check if Weight Loss Injections is selected previously
            var triedMethods = document.querySelector("#text-block-rich-text-1cf7500a .variable-replace").textContent;
            
            // Check if "Weight Loss Injections" is present in the string
            if (triedMethods.includes("Weight Loss Injections")) {
                weightLossInj = "yes";
                
            }
            console.log("wloss inj =" + weightLossInj);
            
            var clFt = Number(document.querySelector("#text-block-rich-text-cfa7d037 .variable-replace").textContent);
            var clIn = Number(document.querySelector("#text-block-rich-text-85b2db10 .variable-replace").textContent);
            var clWeight = Number(document.querySelector("#text-block-rich-text-09fb23c1 .variable-replace").textContent);
            
            var clHeight = (clFt * 12) + clIn;
            
            var bmi = (clWeight * 703) / (clHeight * clHeight);
            
            if(bmi > 27){
                var eli = "yes";
            }
            if(bmi > 22 && bmi < 28 && weightLossInj == "yes"){
                var eli = "yes";
                console.log("eli and wlinj = " + eli);
            }
            
            if (eli == "yes"){
                document.getElementById('multiple-choice-block-mc-816f5680').style.display = "none";
                document.getElementById('mco-93f65dfd').checked = false;
            }else{
                document.getElementById('multiple-choice-block-mc-816f5680').style.display = "block";
                document.getElementById('mco-93f65dfd').checked = true;
            }
            
            
        
        }
	}
}


window.addEventListener('heyflow-screen-view', (event) => {
 console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
// console.log('heyflow submit:', event.detail);
});



